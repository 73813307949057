<template>
  <div>
    <v-container v-if="screen <= 400">

      <v-list subheader>
        <v-subheader>Your decks</v-subheader>

        <v-list-item
          v-for="(deck) in decks" :key="deck.id"
        >
          <v-list-item-avatar>
            <v-img
              src="https://picsum.photos/500/300?image=20"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="deck.name"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon @click="$router.push('/deck/' + deck.id + '/learn')">
              mdi-school
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

    </v-container>

    <v-container v-if="screen > 400">
      <v-row dense>
        <v-col col="4">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="green" v-on="on"><v-icon>mdi-plus</v-icon> New</v-btn>
            </template>
            <v-list>
              <v-list-item @click="createDeck">
                <v-list-item-title>Create basic deck</v-list-item-title>
              </v-list-item>
<!--              <v-list-item @click="showDialog = true">-->
<!--                <v-list-item-title>Create from a Google Slides Presentation</v-list-item-title>-->
<!--              </v-list-item>-->
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense>

        <v-col
            v-for="(deck) in decks" :key="deck.id"
            cols="4"
        >
          <v-card class="d-flex flex-column"  >
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
            >
              <v-toolbar-title>
                {{ deck.name }}
              </v-toolbar-title>


              <v-spacer></v-spacer>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="black"
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link>
                    <v-list-item-title @click="deleteDeck({ deckId: deck.id })"><v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </v-app-bar>

            <v-row align="center">
              <v-col align="center">
                <img width="75%" class="secondary text-no-wrap rounded-pill"
                    src="https://picsum.photos/500/300?image=20"
                >
              </v-col>
            </v-row>

            <v-card-actions>
              {{deck.cardsSize ? `${deck.cardsSize} cards` : ""}}
              <v-spacer/>
              <v-btn icon @click="$router.push(getDeckUrl(deck))">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="$router.push('/deck/' + deck.id + '/learn')">
                <v-icon>mdi-school</v-icon>
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-col>

        <v-dialog
            v-model="showDialog"
            persistent
            max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Sync new deck with a Google Slides Presentation
            </v-card-title>
            <v-card-text>Your deck will be created based on a Google Slides Presentation you've made
            <v-text-field v-model="googlePresentationId" placeholder="copy the google slides presentation id here"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="showDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="green darken-1"
                  text
                  @click="createGooglePresentationDeck"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import service from "@/service";

export default {
  name: 'MyDecks',

  components: {},

  data: () => ({
    selectedDeck: 1,
    showDialog: false,
    googlePresentationId: ""
  }),

  mounted() {
    this.loadUser()
  },

  computed: {
    decks: {
      get() {
        const decks =  this.getDecks().map(d => {
          console.log(d.updateDate)
          d.updateDate = d.updateDate == undefined ? 0 : d.updateDate
          return d
        })
        console.log("before sort" + decks.map(d => d.updateDate))
        decks.reverse((d1, d2) => d1.updateDate - d2.updateDate )
        console.log("after sort" + decks.map(d => d.updateDate))
        return decks
      }
    },

    screen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
        default: return 400
      }
    },
  },

  methods: {
    ...mapActions([
        'createDeck',
        'loadUser',
        'deleteDeck'
    ]),
    ...mapGetters([
      'getDecks'
    ]),
    
    getDeckUrl(deck) {
      console.log("getDeckUrl:" + JSON.stringify(deck)) 
      if (deck.type === "FOLLOW") {
        return '/deck/' + deck.id + '/view'
      } 
      return '/deck/' + deck.id + '/edit'
    },

    async createGooglePresentationDeck() {
      this.showDialog = false
      const deck = await service.createGooglePresentationSyncDeck(this.googlePresentationId)
      this.getSlidesAuthUrl(deck.id)
    },
    async getSlidesAuthUrl (deckId) {
      const url = await service.getSlidesAuthUrl(deckId);
      console.log(url)
      window.open(
          url, "window name",
          "height=200,width=200,modal=yes,alwaysRaised=yes");
    }
  }
}
</script>
