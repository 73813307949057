<template>
  <div>

    <v-sheet
        color="white"
        elevation="0"
    >
      <v-app-bar flat>
        <v-btn depressed fab @click="goBack" v-if="loadedLearning.sessionInProgress">
          <v-icon dark>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title>
          {{deck.name}}
        </v-toolbar-title>
      </v-app-bar>
      <learning-continue-session-page v-if="showLearningWelcomePage && loadedLearning.sessionInProgress"
                                      @startSession="startSession"
      />

      <learning-welcome-page v-if="showLearningWelcomePage && !loadedLearning.sessionInProgress"
        :cardsToLearnCount="cardsInThisSessionTotalSize"
        :boxesSummary="boxesSummary"
        @startSession="startSession"
      />

      <learning-end-page v-if="!showLearningWelcomePage && !hasCardToLearn"
        :cardsToLearnCount="cardsInThisSessionTotalSize"
        :boxesSummary="boxesSummary"
        @startSession="startSession"
      />

      <v-container fill-height fluid v-if="!showLearningWelcomePage && hasCardToLearn">
        <v-row align="center" justify="center">
          <v-col class="d-flex  justify-center align-center">
              <learning-card :cardId="currentCard.id" :front-json="currentCard.front" :back-json="currentCard.back" :side-to-show="sideToShowDuringLearning" />
            <!-- <v-card width="600" height="424" v-show="!hasCardToLearn">
              Well done ! No More card to learn for this session
            </v-card> -->
          </v-col>
        </v-row>
        <v-row align="center">
          <v-progress-linear height="25" color="blue" :value="100*(cardsInThisSessionDoneSize/cardsInThisSessionTotalSize)">
            <strong>{{ cardsInThisSessionDoneSize}}/{{cardsInThisSessionTotalSize }}</strong>
          </v-progress-linear>
        </v-row>
        <v-row align="center">
          <v-col class="d-flex  justify-center align-center">
            <v-btn outlined rounded text @click="seeItAgain" color="red">See it again</v-btn>
          </v-col>
          <v-col class="d-flex  justify-center align-center">
            <v-btn outlined rounded text @click="difficult" color="orange">Hard</v-btn>
          </v-col>
          <v-col class="d-flex  justify-center align-center">
            <v-btn outlined rounded text @click="good" color="green">Good</v-btn>
          </v-col>
        </v-row>
      </v-container>   
    </v-sheet>


    <v-overlay :value="loadingCardsFlag">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay> 

  </div>
</template>

<script>

import LearningCard from "@/components/LearningCard";
import service from "@/service";
import {LearningCardAlgo} from "@/LearningCardAlgo";
import {DeckBO} from "@/Deck";
import LearningWelcomePage from './LearningWelcomePage.vue';
import LearningEndPage from './LearningEndPage.vue';
import LearningContinueSessionPage from "@/components/LearningContinueSessionPage";

let learningCardAlgo = null
let deckBO = new DeckBO()

export default {
  name: "DeckLearner",
  components: {LearningContinueSessionPage, LearningCard, LearningWelcomePage, LearningEndPage},

  data: () => ({
    showLearningWelcomePage: true,
    tab: null,
    boxesSummary: [],
    loadingCardsFlag: true,
    hasCardToLearn: false,
    currentCard: {
      id: "",
      front: "",
      back: ""
    },
    deck: {
      name: "",
      cards: []
    },
    loadedCards: [],
    loadedLearning: {
      id: "",
      session: 1,
      sessionInProgress: false,
      boxesOfCards: {0:[]}
    },
    sideToShowDuringLearning: "front",
    cardsInThisSessionTotalSize: 0,
    cardsInThisSessionDoneSize: 0,
    numberOfNewCardsToLearn: 4,
  }),

  mounted: async function() {
    this.loadingCardsFlag = true
    const deckId = this.$route.params.deckId
    await this.loadDeck(deckId)
    this.loadedLearning = await this.loadLearning(deckId)
    learningCardAlgo = new LearningCardAlgo(this.loadedLearning)
    const allCardIdsToLearn = learningCardAlgo.getAllCardIdsToLearn()
    if (allCardIdsToLearn.length > 0) {
      deckBO.loadCards(allCardIdsToLearn)
    }
    this.loadingCardsFlag = false
    this.cardsInThisSessionTotalSize = learningCardAlgo.getNumberOfCardToLearn()
    this.boxesSummary = learningCardAlgo.getBoxesSummary()
  },

  computed: {
  },

  methods: {
    startSession(startSessionEvent) {
      this.sideToShowDuringLearning = startSessionEvent.sideToShow
      console.log("sideToShowDuringLearning=" + this.sideToShowDuringLearning)
      learningCardAlgo.startSession(startSessionEvent.numberOfNewCardsToLearn)
      this.loadedLearning.sessionInProgress = true
      let currentCardIdToLearn = learningCardAlgo.getCurrentCardIdToLearn();

      // vérifier que toutes les cartes dans learningCardAlgo.getAllCardIdsToLearn() sont bien chargées:
      const cardIdAddedAndNotLoaded = []
      for (let cardId of learningCardAlgo.getAllCardIdsToLearn() ) {
        let card = this.loadedCards.find(card => card.id === cardId)
        if (card == null) { // peut arriver si la carte a été ajouté au learning
          cardIdAddedAndNotLoaded.push(cardId)
        }
      }
      deckBO.loadCards(cardIdAddedAndNotLoaded).then(() => {
        this.hasCardToLearn = learningCardAlgo.hasCardsToLearn()
        this.currentCard = deckBO.getCardById(currentCardIdToLearn)
        console.log("currentCard=" + JSON.stringify(this.currentCard))
        this.cardsInThisSessionTotalSize = learningCardAlgo.getNumberOfCardToLearn()
        this.cardsInThisSessionDoneSize = learningCardAlgo.getNumberOfCardLearned()
        this.showLearningWelcomePage = false
      })
    },

    loadDeck: async function(deckId, withAllCards = false) {
      await deckBO.loadDeck(deckId, withAllCards)
      this.deck = deckBO.getLoadedDeck()
      this.loadedCards = deckBO.getLoadedCards()
    },

    loadLearning: async function(deckId) {
      const loadedLearning = await service.loadLearning(deckId)
      for (let i = 0; i < 8; i++) {
        if (loadedLearning.boxesOfCards[i] === undefined) {
          loadedLearning.boxesOfCards[i] = []
        }
      }
      return loadedLearning
    },

    seeItAgain: function() {
      console.log("seeItAgain")
      learningCardAlgo.seeItAgain()
      this.showNextCard()
    },

    difficult: function() {
      console.log("difficult")
      learningCardAlgo.difficult()
      this.showNextCard()
      this.saveLearningSession()
    },

    good: function() {
      console.log("good")
      learningCardAlgo.good()
      this.showNextCard()
      this.saveLearningSession()
    },

    showNextCard() {
      this.hasCardToLearn = learningCardAlgo.hasCardsToLearn()
      if (learningCardAlgo.hasCardsToLearn()) {
        let currentCardIdToLearn = learningCardAlgo.getCurrentCardIdToLearn();
        this.currentCard = deckBO.getCardById(currentCardIdToLearn)
        this.cardsInThisSessionDoneSize = learningCardAlgo.getNumberOfCardLearned()
        console.log(100*(this.cardsInThisSessionDoneSize/this.cardsInThisSessionTotalSize));
      } else {
        this.loadedLearning.sessionInProgress = false
        this.saveLearningSession()
        this.boxesSummary = learningCardAlgo.getBoxesSummary()
      }
    },

    saveLearningSession() {
      console.log("saveLearningSession\n")
      this.loadedLearning.boxesOfCards = learningCardAlgo.boxesOfCardIdsToLearnResult
      let copyOfCardIdsToLearn = [...learningCardAlgo.cardIdsToLearn]
      console.log("cardIdsNotToSaveWhenSavingSession=" + learningCardAlgo.cardIdsNotToSaveWhenSavingSession)
      console.log("copyOfCardIdsToLearn=" + copyOfCardIdsToLearn)
      learningCardAlgo.cardIdsNotToSaveWhenSavingSession.forEach(cardId => {
        console.log("")
        console.log("cardId=" + cardId)
        console.log("index of cardId in copyOfCardIdsToLearn=" + copyOfCardIdsToLearn.indexOf(cardId))

        copyOfCardIdsToLearn.splice(copyOfCardIdsToLearn.indexOf(cardId), 1)
        console.log("after splice copyOfCardIdsToLearn=" + copyOfCardIdsToLearn)
        console.log("")
      })

      this.loadedLearning.cardIdsInSession = copyOfCardIdsToLearn
      this.loadedLearning.seeItAgainBox = learningCardAlgo.seeItAgainBox
      if (!this.loadedLearning.sessionInProgress) {
        this.loadedLearning.session++
        this.loadedLearning.seeItAgainBox = []
      }
      service.saveLearning(this.loadedLearning)
    },

    decrement() {
      this.numberOfNewCardsToLearn--
    },

    increment() {
      this.numberOfNewCardsToLearn++
    },

    goBack() {
      this.$router.push('/mydecks/')
    },
  },

}
</script>
