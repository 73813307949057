<template>
  <div>
    <v-row dense>

          <v-text-field
              v-model="searchTerm"
              hide-details
              single-line
              prepend-icon="mdi-magnify"
              placeholder="search for deck"
              @keypress.enter="searchDecks"
          ></v-text-field>

    </v-row>
    <v-row dense>
      <v-col
          v-for="(deck) in decks" :key="deck.id"
          cols="3"
      >

        <v-card class="d-flex flex-column"  >
          <v-app-bar
              flat
              color="rgba(0, 0, 0, 0)"
          >
            <v-toolbar-title>
              {{ deck.name }}
            </v-toolbar-title>

          </v-app-bar>

          <v-row align="center">
            <v-col align="center">
              <img width="75%" class="secondary text-no-wrap rounded-pill"
                   src="https://picsum.photos/500/300?image=20"
              >
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer/>
            <v-btn icon @click="$router.push('/deck/' + deck.id + '/view')">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>

    </v-row>
  </div>
</template>

<script>
import service from "@/service";

export default {
  name: 'Home',

  data: () => ({
    searchTerm: "",
    decks: []
  }),

  methods : {
    async searchDecks() {
      console.log("searching for " + this.searchTerm)
      this.decks = await service.searchDecks(this.searchTerm)
    }
  }
}
</script>
