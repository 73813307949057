<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  name: "Chart",

  props: ["values"],

  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: "# of cards",
          data: [],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      responsive: true,
      maintainAspectRatio: false
    },
  }),

  mounted() {
    console.log(JSON.stringify(this.values))
    this.chartData.labels = this.values.map(val => val.label)
    this.chartData.datasets[0].data = this.values.map(val => val.value)

    this.renderChart(this.chartData, this.options);
  },
};
</script>