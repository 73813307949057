<template>

  <v-container fluid fill-height class="pa-0">
    <v-sheet class="pa-4">
      <v-app-bar
          flat
      >

        <v-toolbar-title class="text-h6 black--text pl-0" >
          {{title}}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon v-if="write" @click="addText">
          <v-icon>mdi-format-text</v-icon>
        </v-btn>
    </v-app-bar>

      <v-sheet  :elevation="selected?3:1" rounded @click="canvasSelected" >
        <canvas ref="can"></canvas>
      </v-sheet>
    </v-sheet>

  </v-container>
</template>

<script>
  // import {fetchWithIdToken} from "@/ApiHelper";
  import {fabric} from "fabric";
  import {createUUID} from "@/UUIDHelper";
  import service from "@/service";
  // import debounce from 'debounce'

  export default {
    name: 'SideEditor',

    props: ["title", "write", "selected", "ownerId", "deckId", "cardId", "canvasJson"],

    watch: {
      canvasJson: function(newVal) {
        console.log('canvasJson changed')
        this.updateCanvas(newVal)
      },
    },

    created : function() {
      // when the component has been created,
      // we replaced the original method with a debounced version
      // https://forum.vuejs.org/t/how-to-delay-watcher-method-call-on-an-input-field/34321
      // this.canvasModifiedCallback = debounce(this.canvasModifiedCallback, 200)
    },

    beforeDestroy() {
      console.log("SideEditor.beforeDestroy")
      // this is to avoid to have more than one time the function handleClick registered
      // (eeach time we enter in the mounted function)
      window.removeEventListener("keydown", this.deleteObject)
      window.removeEventListener("resize", this.onResize)
    },

    async mounted() {
      console.log("mounting side editor")
      this.initCanvas()

      // const vm = this
      // TODO: ? pas sur que ce addEventListener soit très utile puisqu'on l'attache aussi dans canvasSelected
      //window.addEventListener('paste', this.copyAndPast, {once: true});

      window.addEventListener("keydown", this.deleteObject)
      window.addEventListener("resize", this.onResize);

    },

    data: () => ({
      canvas: undefined,
      loading: false,
      editing: false,
      canvasWidth: 0,
      canvasHeight: 0,
    }),

    methods: {

      makeContentUnselectableIfNecessary() {
        if (this.write == false) {
          this.canvas.getObjects().forEach((obj) => {
            console.log("obj.selectable=" + obj.selectable)
            obj.selectable=false
          });  
        }
      },

      initCanvas() {
        const ref = this.$refs.can;
        this.canvas = new fabric.Canvas(ref)
        this.updateCanvas(this.canvasJson)
        this.onResize()
        this.canvas.on('object:removed', this.canvasModifiedCallback);
        this.canvas.on('object:modified', this.canvasModifiedCallback);
      },

      onResize() {
        const winH = window.innerHeight
      
        // trying to write an equation that would describe kind of a linear relation between the size of the window and the size of the canvas
        // but it's currently actually a bit clunky :-/
        this.canvasHeight = 1.3 * winH - 560

        this.canvasWidth = this.canvasHeight * 1.414
        const zoom = this.canvasWidth/450
    
        this.canvas.setWidth(this.canvasWidth);
        this.canvas.setHeight(this.canvasHeight);
        this.canvas.setZoom(zoom)
        this.canvas.renderAll();        
      },

      canvasSelected: function() {
        this.$emit("selected")
        console.log("event selected emited")
        window.addEventListener('paste', this.copyAndPast, {once: true});
      },

      updateCanvas: function(canvasJson) {
        console.log("updateCanvas")
        this.loading = true
        if (!canvasJson) {
          const vm = this
          this.canvas.loadFromJSON('{}', function () {
            vm.canvas.renderAll();
            vm.loading = false
            console.log("setting loading to false")
            vm.makeContentUnselectableIfNecessary()
          })
        } else {
          const vm = this
          this.canvas.loadFromJSON(canvasJson, function () {
            vm.canvas.renderAll();
            vm.loading = false
            console.log("setting loading to false")
            vm.makeContentUnselectableIfNecessary()
          })
        }
      },

      canvasModifiedCallback: function() {
        console.log("canvasModifiedCallback loading=" + this.loading)
        if (this.loading === false) {
          this.$emit("cardSideChange", JSON.stringify(this.canvas))
        }
      },

      deleteObject: function(event) {
        console.log("SideEditor.deleteObject:" + event.key)
        if (event.key == "Backspace") {
          this.canvas.getActiveObjects().forEach((obj) => {
            console.log("obj.isEditing=" + obj.isEditing)
            if (obj.isEditing) {
              return
            }
            this.canvas.remove(obj)
          });
          //this.canvas.discardActiveObject().renderAll()
        }
      },

      addText() {
        this.createTextBox("your text")
      },

      createTextBox(txt) {
        console.log("txt:" + txt)
        // const newID = (new Date()).getTime().toString().substr(5);
        const text = new fabric.Textbox(txt, {
          fontFamily: 'Arial',
          width: 442,
          height: 18.08,
          top: 83.25,
          left: 3,
          fontSize: 48,
          textAlign: 'center',
          fixedWidth: 150
        });
        this.canvas.add(text);
        // this.canvas.set({ fill: 'blue' });
        this.canvas.setActiveObject(text)

        text.selectAll()
        text.enterEditing();
      },

      async copyAndPast(e) {
        console.log(`canvas '${this.title}' receive event ${e}`)
        if (!this.selected) {
          console.log(`canvas '${this.title}' not selected, image not pasted`)
          return
        }
        let paste = (e.clipboardData || window.clipboardData);
        if (paste.types[0].indexOf("text") !== -1) {
          const text = paste.getData("text")

          const insertTextInActiveTextBox = this.canvas.getActiveObject() != undefined && this.canvas.getActiveObject().get("type") === "textbox"

          if (!insertTextInActiveTextBox) {
            this.createTextBox(text)
            this.canvasModifiedCallback()
          }

        } else {
          console.log(`canvas '${this.title}' pasting an image`)
          const uuid = createUUID()
          service.saveImage(this.deckId, this.cardId, paste.files[0], uuid)
          .then(imageUrl => {
            const img = new Image();
            img.src = imageUrl
            const vm = this
            fabric.Image.fromURL(img.src, function(img) {
              console.log("img.width=" + img.getScaledWidth() )
              console.log("img.height=" + img.getScaledHeight() )
              console.log("img.getScaledWidth() / img.getScaledHeight()=" + img.getScaledWidth() / img.getScaledHeight() )

              // si on a un ration largeur / hauteur avec une difference de 20%
              // alors on privilégie une mise à l'echelle par rapport à la hauteur:
              const whRatio = img.getScaledWidth() / img.getScaledHeight()
              if (0.8 < whRatio && whRatio < 1.2) {
                console.log("width / height ratio is 80%")
                img.scaleToHeight(450/1.414)
              } else if (img.getScaledWidth() > img.getScaledHeight()) {
                console.log("width is bigger")
                img.scaleToWidth(450)
              } else {
                console.log("height is bigger")
                img.scaleToHeight(450/1.414)
              }

              vm.canvas.add(img)
              img.viewportCenter()
              img.setCoords(false)

              vm.canvasModifiedCallback()
            });
          })
          .then(data => {
            console.log(data)
          })
          .catch(error => {
            console.error(error)
          })
        }
      }

    },
  }
</script>
