import Vue from 'vue'
import Vuex from 'vuex'
import service from "@/service";
import { router}  from './main'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    user: {
      decks: []
    },
  },
  actions: {
    async loadUser({ commit }) {
      console.log("load user action");
      const user = await service.loadUser();
      commit('setUser', user)
    },
    async createDeck({ commit }) {
      console.log("Create deck action");
      const deck = await service.createDeck();
      commit('setNewDeck', deck)
      router.push('/deck/' + deck.id + '/edit')
    },
    async deleteDeck({ commit }, { deckId }) {
      console.log("action => delete " + deckId)
      await service.deleteDeck(deckId)
      commit('removeDeck', deckId)
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setNewDeck(state, deck) {
      state.user.decks.push(deck)
    },
    async removeDeck(state, deckId) {
      console.log("mutation => removeDeck " + deckId)
      state.user.decks = state.user.decks.filter(deck => deck.id !== deckId)
    }
  },
  getters: {
    getUser: state => {
      return state.user
    },
    getDecks: state => {
      return state.user.decks
    },
  }
})
export default store;