<template>
  <v-sheet @click="turnCard" :width="canvasWidth" :height="canvasHeight" elevation="2" rounded >
    <canvas :cardId="cardId" :frontJson="frontJson" ref="cardToLearn"/>
  </v-sheet>
</template>

<script>
import {fabric} from "fabric";

export default {
  name: "LearningCard",

  props: ["cardId", "frontJson", "backJson", "sideToShow"],

  data: () => ({
    width: 600,
    isActiveSideFront: true,
    cardToLearnCanvas: undefined,
    canvasWidth: 0,
    canvasHeight: 0,
  }),

  watch: {
    frontJson: function() {
      console.log("frontJson")
      this.assertSideToShow()
    },
    
  },

  mounted() {
    console.log("learning card mounted")
    console.log("mounted sideToShow= " + this.sideToShow)
    const cardToLearnRef = this.$refs.cardToLearn;
    this.cardToLearnCanvas = new fabric.StaticCanvas(cardToLearnRef)
    this.cardToLearnCanvas.setZoom(this.width/450)
    this.assertSideToShow()
    window.addEventListener("resize", this.onResize);
    this.forceResizeHack()
  },

  methods: {
    onResize() {
      console.log("onResize  window.innerHeight=" +  window.innerHeight + " window.innerWidth=" + window.innerWidth)
      if (window.innerHeight < window.innerWidth) {
        // trying to write an equation that would describe kind of a linear relation between the size of the window and the size of the canvas
        // but it's currently actually a bit clunky :-/
        this.canvasHeight = 0.65 * window.innerHeight
        this.canvasWidth = this.canvasHeight * 1.414
      } else {
        this.canvasWidth = 0.95 * window.innerWidth
        this.canvasHeight = this.canvasWidth / 1.414
      }
      
      const zoom = this.canvasWidth/450
  
      this.cardToLearnCanvas.setWidth(this.canvasWidth);
      this.cardToLearnCanvas.setHeight(this.canvasHeight);
      this.cardToLearnCanvas.setZoom(zoom)
      this.cardToLearnCanvas.renderAll();        
    },

    forceResizeHack() {
      // The following is an horrible hack to force the canvas to resize to the correct size when first loaded
      // Need to call at least 4 times the onResize to correctly feet the space
      setTimeout(this.onResize, 10)
      setTimeout(this.onResize, 20)
      setTimeout(this.onResize, 30)
      setTimeout(this.onResize, 40)
    },

    assertSideToShow() {
      console.log("assertSideToShow")
      switch(this.sideToShow) {
        case "front":
          this.isActiveSideFront = true
          console.log("front")
        break
        case "back":
          this.isActiveSideFront = false
          console.log("back")
        break
        case "random":
          if (Math.random() <= 0.5) {
            this.isActiveSideFront = true 
          } else {
            this.isActiveSideFront = false
          }
          console.log("random")
        break
        default:
          this.isActiveSideFront = true
          console.log("default")
      }
      this.updateSideToShow()
    },

    updateCanvas: function(canvasJson) {

      if (!canvasJson) {
        const vm = this
        this.cardToLearnCanvas.loadFromJSON('{}', function () {
          vm.cardToLearnCanvas.renderAll();
        })
      } else {
        const vm = this
        this.cardToLearnCanvas.loadFromJSON(canvasJson, function () {
          vm.cardToLearnCanvas.renderAll();
        })
      }

    },

    turnCard: function() {
      this.isActiveSideFront = !this.isActiveSideFront
      this.updateSideToShow()
    },

    updateSideToShow: function() {
      if (this.isActiveSideFront) {
        this.updateCanvas(this.frontJson)
      } else {
        this.updateCanvas(this.backJson)
      }
    },
    
  }
}
</script>
