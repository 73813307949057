import {fetchWithIdToken, fetchBasic} from "@/ApiHelper";

async function userSignedIn() {
  await fetchWithIdToken(
      "/api/secure/user/signin",
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: ""
      });
}
async function saveCard(card) {
  console.log(card)
  const response = await fetchWithIdToken(
      "/api/secure/card",
      {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(card)
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when saving card: " + res)
    throw res
  }
  return;
}

async function createDeck() {
  const response = await fetchWithIdToken(
      "/api/secure/deck",
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ name: "a deck"})
      });

  return response.json()
}


async function followDeck(deckId) {
  const response = await fetchWithIdToken(
      `/api/secure/deck/${deckId}/follow`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when following deck: " + res)
    throw res
  }
  return response.json();
}

async function createGooglePresentationSyncDeck(googlePresentationId) {
  const response = await fetchWithIdToken(
      "/api/secure/deck",
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ name: "a deck", type: "GOOGLE_PRESENTATION", googlePresentationId: googlePresentationId })
      });

  return response.json()
}

async function deleteDeck(deckId) {
  const response = await fetchWithIdToken(
      `/api/secure/deck/${deckId}`,
      {
        method: 'DELETE',
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when deleting deck: " + res)
    throw res
  }
  return response.json();
}

async function loadDeck(deckId) {
  const response = await fetchBasic(
      `/api/deck/${deckId}`,
      {
        method: 'GET',
      });

  return response.json()
}

async function searchDecks(nameContains) {
  const response = await fetchBasic(
      `/api/deck/search?name=${nameContains}`,
      {
        method: 'GET',
      });

  return await response.json()
}

async function loadUser() {
  console.log("loadUser")
  const response = await fetchWithIdToken(
      "/api/secure/user",
      {
        method: 'GET',
      });

  return response.json()
}

async function validateTermsOfUse() {
  const response = await fetchWithIdToken(
      `/api/secure/user/termsofuse`,
      {
        method: 'POST',
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when validating terms of use: " + res)
    throw res
  }
  return response.json();
}

async function addCard(deckId) {
  const response = await fetchWithIdToken(
      `/api/secure/card/${deckId}`,
      {
        method: 'POST',
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when deleting card: " + res)
    throw res
  }
  return response.json();
}

async function deleteCard(userId, deckId, cardId) {
  const response = await fetchWithIdToken(
      `/api/secure/card/${deckId}/${cardId}`,
      {
        method: 'DELETE',
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when deleting card: " + res)
    throw res
  }
}

async function loadCard(deckId, cardId) {
  console.log(`service.loadCard:(deckId=${deckId}, cardId=${cardId})`)
  const response = await fetchBasic(
      `/api/card/${deckId}/${cardId}`,
      {
        method: 'GET',
      });

  const card = await response.json()
  if (card.type === "IMAGE_SLIDE") {
    card.front = `{"version":"4.3.1","objects":[{"type":"image","version":"4.3.1","originX":"left","originY":"top","left":-0.14,"top":21.73,"width":800,"height":450,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":0.53,"scaleY":0.53,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"cropX":0,"cropY":0,"src":"https://storage.googleapis.com/easyflashcard-image-storage/${card.userId}/${card.deckId}/${card.id}/${card.front}","crossOrigin":null,"filters":[]}]}`
    card.back = `{"version":"4.3.1","objects":[{"type":"image","version":"4.3.1","originX":"left","originY":"top","left":-0.14,"top":21.73,"width":800,"height":450,"fill":"rgb(0,0,0)","stroke":null,"strokeWidth":0,"strokeDashArray":null,"strokeLineCap":"butt","strokeDashOffset":0,"strokeLineJoin":"miter","strokeUniform":false,"strokeMiterLimit":4,"scaleX":0.53,"scaleY":0.53,"angle":0,"flipX":false,"flipY":false,"opacity":1,"shadow":null,"visible":true,"backgroundColor":"","fillRule":"nonzero","paintFirst":"fill","globalCompositeOperation":"source-over","skewX":0,"skewY":0,"cropX":0,"cropY":0,"src":"https://storage.googleapis.com/easyflashcard-image-storage/${card.userId}/${card.deckId}/${card.id}/${card.back}","crossOrigin":null,"filters":[]}]}`
  }

  return card
}

async function updateDeckName(deckId, deckName) {
  const response = await fetchWithIdToken(
      `/api/secure/deck/${deckId}/${deckName}`,
      {
        method: 'PUT',
      });
  if (!response.ok) {
    const res = await response.text();
    console.log("Error when updating deck name: " + res)
    throw res
  }
  return response.json()
}

async function loadLearning(deckId) {
  const response = await fetchWithIdToken(
      `/api/secure/learning/${deckId}`,
      {
        method: 'GET',
      });

  return response.json()
}


async function saveLearning(learning) {
  console.log("saveLearning=" + JSON.stringify(learning))
  await fetchWithIdToken(
      "/api/secure/learning",
      {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(learning)
      });
}

async function getSlidesAuthUrl(deckId) {
  const response = await fetchWithIdToken(
      `/api/secure/slides/auth/request?deckId=${deckId}`,
      {
        method: 'GET',
      });

  return response.json()
}

async function saveImage(deckId, cardId, file, uuid) {
  const formData = new FormData()
  formData.append('myFile', file)
  formData.append('uuid', uuid)
  const response = await fetchWithIdToken(
      `/api/secure/card/${deckId}/${cardId}/image`,
      {
        method: 'POST',
        body: formData
      });
  return response.text()
}

export default {
  userSignedIn,
  saveCard,
  loadCard,
  createDeck,
  followDeck,
  createGooglePresentationSyncDeck,
  deleteDeck,
  loadUser,
  validateTermsOfUse,
  loadDeck,
  addCard,
  deleteCard,
  updateDeckName,
  loadLearning,
  saveLearning,
  searchDecks,
  getSlidesAuthUrl,
  saveImage
}
