import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";

// let currentUser = {
//   data: undefined
// }
//
// function saveConnectedUser(user) {
//   return currentUser.data = user
// }

function isUserConnected() {
  const isUserConnected =  getAuth().currentUser !== undefined && getAuth().currentUser !== null
  console.log("isUserConnected="+isUserConnected)
  return isUserConnected
}

function signIn() {
  const provider = new GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/drive.file')
  // provider.addScope('https://www.googleapis.com/auth/presentations.readonly')
  signInWithPopup(getAuth(), provider);
}

function signOut() {
  getAuth().signOut()
}

export default {
  signIn,
  signOut,
  isUserConnected,
}
