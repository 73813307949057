import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from "@/store";
import VueRouter from 'vue-router'
import Home from "@/components/Home";
import MyDecks from "@/components/MyDecks";
import DeckEditor from "@/components/DeckEditor";
import DeckLearner from "@/components/DeckLearner";
import {getAuth} from "firebase/auth";
import TermsOfUse from "@/components/TermsOfUse";


Vue.config.productionTip = false

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'hash',
  routes: [
    { path: '/', redirect: "/mydecks"},
    { path: '/search', component: Home},
    { path: '/mydecks', component: MyDecks, meta: { authRequired: true }},
    { path: '/deck/:deckId/edit', component: DeckEditor, meta: { authRequired: true }, props: { write: true }},
    { path: '/deck/:deckId/view', component: DeckEditor, meta: { authRequired: false }, props: { write: false }},
    { path: '/deck/:deckId/learn', component: DeckLearner, meta: { authRequired: true }},
    { path: '/termsofuse', component: TermsOfUse},
  ]
})

export let loadedTo = undefined

router.beforeEach((to, from, next) => {
  console.log("router")
  if (to.matched.some(record => record.meta.authRequired)) {
    if (getAuth().currentUser) {
      next();
    } else {
      // alert('You must be logged in to see this page');
      console.log("not logger record the next function:" + next)
      loadedTo = function() {
        console.log("using loadedTo")
        next(to)
        loadedTo = undefined
      }
      next({
        path: '/search',
      });
    }
  } else {
    console.log("reset loadedTo")
    next();
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
