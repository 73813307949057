<template>
 <v-container fluid>
   <v-row align="center" justify="center" >
    <v-sheet :width="width+6" :height="height+6" elevation="1" rounded outlined :style="selected ? 'border: 3px solid red;' : ''">
      <canvas :cardId="cardId" :frontJson="frontJson" ref="minicard" :width="width" :height="height"/>
    </v-sheet>
   </v-row>
 </v-container>
</template>

<script>
import {fabric} from "fabric";
const WIDTH = 150
export default {
  name: 'MiniCard',

  props: ["cardId", "frontJson", "selected"],

  data: () => ({
    width: WIDTH,
    height: WIDTH/1.414,
    minicard: undefined
  }),

  watch: {
    frontJson: function(newVal) {
      console.log('frontJson changed')
      this.updateMinicard(newVal)
    }
  },

  mounted() {
    const minicardRef = this.$refs.minicard;
    this.minicard = new fabric.StaticCanvas(minicardRef)
    this.minicard.setZoom(WIDTH/450)
    this.updateMinicard(this.frontJson)
  },

  methods: {
    updateMinicard: function(canvasJson) {
      if (!canvasJson) {
        const vm = this
        this.minicard.loadFromJSON('{}', function () {
          vm.minicard.renderAll();
        })
      } else {
        const vm = this
        this.minicard.loadFromJSON(canvasJson, function () {
          vm.minicard.renderAll();
        })
      }
    },

  }

}
</script>
