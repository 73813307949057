<template>
  <v-row flat>

    <v-col>

      <v-card flat class="mx-auto" max-width="600">
        <v-card-title class="text-h5">
          Continue previous unfinished learning session
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="text-left">
              <v-radio-group v-model="sideToShowDuringLearning">
                <template v-slot:label>
                  <span class="font-weight mr-1">Your preference for card side to show:</span>

                </template>
                <v-radio class="font-weight-light mr-1"
                         label="Show front side first"
                         value="front"
                ></v-radio>
                <v-radio class="font-weight-light mr-1"
                         label="Show back side first"
                         value="back"
                ></v-radio>
                <v-radio class="font-weight-light mr-1"
                         label="Randomly use front or back side"
                         value="random"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row justify="space-between">
            <v-col class="text-right">
              <v-btn
                  color="green"
                  black
                  depressed
                  fab
                  @click="startSession"
              >
                <v-icon large color="white">
                  mdi-play
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-col>

  </v-row >
</template>

<script>
export default {
  name: "LearningContinueSessionPage",

  components: {},

  props: ["cardsToLearnCount", "boxesSummary"], 
  data: () => ({
    sideToShowDuringLearning: "front"
  }),

  mounted() {

  },

  methods: {
    startSession() {
      console.log("startSession event")
      this.$emit("startSession", 
        { 
          numberOfNewCardsToLearn: 0,
          sideToShow: this.sideToShowDuringLearning 
        })
    }
  }

}
</script>
