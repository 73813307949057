<template>
  <v-row flat>
    
    <v-col>
      <v-tabs
            v-model="tab"
            align-with-title
          >
            <v-tab>Learning session</v-tab>
            <v-tab>Learning Summary</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>   
          
          <v-card flat class="mx-auto" max-width="600">
            <v-card-text>
              <v-row>
                <v-col class="text-left">
                  <span class="subheading font-weight-light mr-1">During this session you will review</span>
                  <span
                      class="text-h5 font-weight-light"
                      v-text="cardsToLearnCount"></span>
                  <span class="subheading font-weight-light mr-1"> cards</span> 
                  <span class="subheading font-weight-light mr-1">and you will learn</span>
                  <span
                      class="text-h5 font-weight-light"
                      v-text="numberOfNewCardsToLearn"></span>
                  <span class="subheading font-weight-light mr-1"> new cards</span>                   
                </v-col>  
              </v-row>
              <v-row>
                <v-col class="text-left">
                  <span class="subheading font-weight-light mr-1">You can add new card to learn: </span>
                </v-col>
                <v-col class="text-left">
                  
                  <v-slider
                    v-model="numberOfNewCardsToLearn"
                    track-color="grey"
                    always-dirty
                    min="0"
                    max="30"
                  >
                    <template v-slot:prepend>
                      <v-icon
                          @click="decrement"
                      >
                        mdi-minus
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon
                          @click="increment"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                  </v-slider>
                </v-col>  
              </v-row>
              
              <v-row>
                <v-col class="text-left">
                  <v-radio-group v-model="sideToShowDuringLearning">
                    <template v-slot:label>
                      <span class="font-weight mr-1">Your preference for card side to show:</span>
                      
                    </template>
                    <v-radio class="font-weight-light mr-1"
                      label="Show front side first"
                      value="front"
                    ></v-radio>
                    <v-radio class="font-weight-light mr-1"
                      label="Show back side first"
                      value="back"
                    ></v-radio>
                    <v-radio class="font-weight-light mr-1"
                      label="Randomly use front or back side"
                      value="random"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col class="text-right">
                  <v-btn
                      color="green"
                      black
                      depressed
                      fab
                      @click="startSession"
                  >
                    <v-icon large color="white">
                      mdi-play
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-title class="text-h5">
              Cards Chart
            </v-card-title>
              <div>
                <Chart :values="boxesSummary"></Chart>
              </div>
          </v-card>
          
        </v-tab-item>
      </v-tabs-items>
    </v-col>
        
  </v-row >
</template>

<script>
import Chart from './Chart.vue';  

export default {
  name: "LearningWelcomePage",

  components: {Chart},

  props: ["cardsToLearnCount", "boxesSummary"], 
  data: () => ({
    tab: null,
    numberOfNewCardsToLearn: 4,
    sideToShowDuringLearning: "front",
  }),

  mounted() {

  },

  methods: {
    decrement() {
      this.numberOfNewCardsToLearn--
    },

    increment() {
      this.numberOfNewCardsToLearn++
    },

    startSession() {
      console.log("startSession event")
      this.$emit("startSession", 
        { 
          numberOfNewCardsToLearn: this.numberOfNewCardsToLearn, 
          sideToShow: this.sideToShowDuringLearning 
        })
    }
  }

}
</script>
