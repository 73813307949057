export class LearningCardAlgo {

  constructor(learning) {
    this.learning = learning
    this.cardIdsToLearn = learning.cardIdsToLearn || []
    this.boxesOfCardIdsToLearnIndex = 0
    this.seeItAgainBox = []
    this.cardIdsNotToSaveWhenSavingSession = [] // contient les cards que l'on a déjà passé (en cliquant sur les boutons "easy" ou "difficult")
    this.boxesOfCardIdsToLearnResult = [[]] // contient le résultat de la session d'apprentissage
    if (learning.sessionInProgress) {
      this.cardIdsToLearn = learning.cardIdsInSession
    } else {
      this._decideWhichCardsToLearnForSession()
    }
    // if (this.cardIdsToLearn.length === 0) {
    //   this.learning.session++
    // }
  }

  _decideWhichCardsToLearnForSession() {
    this.cardIdsToLearn = []
    for (let box=1; box <= 7 ; box++) {
      if (this._useBox(box, this.learning.session)) {
        console.log(`box ${box} has ${this.learning.boxesOfCards[box].length} cards to learn`)
        let cardIds = this.learning.boxesOfCards[box];
        this.cardIdsToLearn = this.cardIdsToLearn.concat(cardIds)
      } else {
        console.log(`box ${box} is not in the session`)
      }
    }

    // S'il n'y a pas de card à réviser pour la session et que la box 0 est vide alors chercher 
    // la prochaine session avec des cards à réviser
    if (this.cardIdsToLearn.length === 0 && this.learning.boxesOfCards[0].length === 0) {
      console.log(`session ${this.learning.session} has no card to learn looking for next session`)
      this.learning.session++
      this._decideWhichCardsToLearnForSession()
    }
    console.log(this.cardIdsToLearn)
  }

  _useBox(box, session) {
    const boxMapModulo = {
      1: 1,
      2: 3,
      3: 6,
      4: 10,
      5: 15,
      6: 21,
      7: 28
    }
    console.log("boxMapModulo[" + box + "]= " + boxMapModulo[box] + " and session " + session + "=>" + !(session % boxMapModulo[box]))

    return !(session % boxMapModulo[box])
  }

  startSession(numberOfNewCardsToAddToLearning=0) {
    // ajouter le nombre de nouvelle carte demandé (numberOfNewCardsToAddToLearning) à apprendre pour cette session
    console.log(`adding ${numberOfNewCardsToAddToLearning} cards from box0 (which contains ${this.learning.boxesOfCards[0].length} cards) to session` )
    this.cardIdsToLearn = this.cardIdsToLearn.concat(this.learning.boxesOfCards[0].slice(0, numberOfNewCardsToAddToLearning))
    console.log("cardIdsToLearn.length=" + this.cardIdsToLearn.length)

    // créer une copie (celle que l'on modifie au fur et à mesure des actions good ou difficult)
    // this.boxesOfCardIdsToLearnResult = JSON.parse(JSON.stringify(this.learning.boxesOfCards))
    for (let box=0; box <= 7 ; box++) {
      this.boxesOfCardIdsToLearnResult[box] = this.learning.boxesOfCards[box]
    }
    console.log(this.boxesOfCardIdsToLearnResult)

    this.boxesOfCardIdsToLearnIndex = 0
  }

  seeItAgain() {
    this.seeItAgainBox.push(this.getCurrentCardIdToLearn())
    this.boxesOfCardIdsToLearnIndex ++
  }

  difficult() {
    this.cardIdsNotToSaveWhenSavingSession.push(this.getCurrentCardIdToLearn())
    this._downgradeCurrentCardToFirstBox()
    this.boxesOfCardIdsToLearnIndex ++
  }

  good() {
    this.cardIdsNotToSaveWhenSavingSession.push(this.getCurrentCardIdToLearn())
    console.log(this.cardIdsNotToSaveWhenSavingSession)
    this._upgradeCurrentCardToNextBox()
    this.boxesOfCardIdsToLearnIndex ++
  }

  _downgradeCurrentCardToFirstBox() {
    let currentCardIdToLearn = this.getCurrentCardIdToLearn();
    const result = this._searchCardIdInBoxes(currentCardIdToLearn, this.boxesOfCardIdsToLearnResult)
    const cardIdToDowngrade = this._removeAndGetCardFromBox(result.boxNumber, result.cardIndex)
    // card to first box
    this.boxesOfCardIdsToLearnResult[1].push(cardIdToDowngrade)
  }

  _upgradeCurrentCardToNextBox() {
    let currentCardIdToLearn = this.getCurrentCardIdToLearn();
    // récupérer le numéro de la box et l'index dans cette box de la carte en cours
    const result = this._searchCardIdInBoxes(currentCardIdToLearn, this.boxesOfCardIdsToLearnResult)
    const cardIdToUpgrade = this._removeAndGetCardFromBox(result.boxNumber, result.cardIndex)
    // card to next box
    if (result.boxNumber+1 <= 7) {
      this.boxesOfCardIdsToLearnResult[(result.boxNumber + 1)].push(
          cardIdToUpgrade)
      console.log("après upgrade de la carte: ")
    } else {
      // les cartes dans la box 7 sont celles qui sont apprises
      this.boxesOfCardIdsToLearnResult[result.boxNumber].push(
          cardIdToUpgrade)
    }
    console.log(this.boxesOfCardIdsToLearnResult)
  }

  _removeAndGetCardFromBox(boxNumber, cardIndex) {
    return this.boxesOfCardIdsToLearnResult[boxNumber].splice(cardIndex, 1)[0]
  }

  _searchCardIdInBoxes(cardId, boxes) {
    for (let boxNumber = 0; boxNumber < boxes.length; boxNumber++) {
      const box = boxes[boxNumber]
      if (box.indexOf(cardId) >= 0) {
        return {
          boxNumber,
          cardIndex: box.indexOf(cardId)
        }
      }
    }
    return null;
  }

  hasCardsToLearn() {
    const allCardsToLearn = this.cardIdsToLearn.concat(this.seeItAgainBox)
    return this.boxesOfCardIdsToLearnIndex < allCardsToLearn.length
  }

  getCurrentCardIdToLearn() {
    const allCardsToLearn = this.cardIdsToLearn.concat(this.seeItAgainBox)
    return allCardsToLearn[this.boxesOfCardIdsToLearnIndex]
  }

  getNumberOfCardToLearn() {
    return this.cardIdsToLearn.length
  }

  getNumberOfCardLearned() {
    const allCardsToLearn = this.cardIdsToLearn.concat(this.seeItAgainBox)
    console.log(`this.seeItAgainBox=${this.seeItAgainBox} | this.cardIdsToLearn.length=${this.cardIdsToLearn.length} | allCardsToLearn.length=${allCardsToLearn.length} | this.boxesOfCardIdsToLearnIndex=${this.boxesOfCardIdsToLearnIndex}`)
    return this.cardIdsToLearn.length - (allCardsToLearn.length - this.boxesOfCardIdsToLearnIndex)
  }

  getAllCardIdsToLearn() {
    return this.cardIdsToLearn
  }

  /**
   * Create a summary of the current learning.
   * @returns []{
   *  label string, // label to describe the current box
   *  value int,    // number of cards in this box
   * }
   */
  getBoxesSummary() {
    let boxesSummary = []
    let boxesLabels = ["All unlearned cards", "Fresh cards level 1", "Fresh cards level 2", "Fresh cards level 3", "Cards you know Level 1", "Cards you know level 2", "Cards you master level 1", "Cards you master level 2"]
    Object.entries(this.learning.boxesOfCards).forEach((entry, index) => {
      boxesSummary.push({label: `${boxesLabels[index]}`, value: entry[1].length})
    })
    return boxesSummary
  }

}