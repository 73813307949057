<template>
  <v-sheet 
      color="white"
      elevation="0"
  >

    <v-app-bar flat>
      <v-btn depressed fab @click="$router.push('/mydecks')">
        <v-icon dark>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-toolbar-title>
        <input v-if="editTitle" v-model="deck.name"
               @keyup.enter="editTitle = false; updateDeckName()"
               @blur="editTitle = false; updateDeckName()"
               @mouseover="editTitle = true" :disabled="!editable"/>
        <div v-else @click="editTitle = true">{{deck.name}}</div>
      </v-toolbar-title>
      <v-spacer/>
      
      <v-btn depressed v-if="!isOwner && showFollowThisDeckButton" @click="followDeck">
        <v-icon  dark>
          mdi-content-copy
        </v-icon>
        Follow this deck
      </v-btn>
    </v-app-bar>

    <v-sheet>
      <v-row align="stretch" no-gutters >
        <v-col cols="2">

          <v-list flat>
            <v-list-item>
              <v-list-item-content>
                <v-row justify="center">
                    <v-btn v-if="editable" @click="addCard" color="green">
                      New Card
                    </v-btn>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-group
                v-model="selectedCard"
                mandatory
                color="primary"
            >
              <v-virtual-scroll
                  :items="loadedCards"
                  :item-height="120"
                  :height="windowHeight-200"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.id" :ripple="false">
                    <v-list-item-content @click.stop="setCurrentCard(item)">
                      <MiniCard :frontJson="item.front" :cardId="item.id" :selected="currentCard.id == item.id"/>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list-item-group>
          </v-list>

        </v-col>

        <v-col cols="10">
          <card-editor-with-tabs :owner-id="deck.userId" :write="editable" :deck-id="deck.id" :card-id="currentCard.id"
                      :front="currentCard.front" :back="currentCard.back" :front-side-selected="frontSideSelected"
                      @cardFrontSideChange="onCardFrontSideChange"
                      @cardBackSideChange="onCardBackSideChange"
                      @cardFrontSideSelected="setFrontSideSelected(true)"
                      @cardBackSideSelected="setFrontSideSelected(false)"/>
        </v-col>
      </v-row>
    </v-sheet>
    <v-snackbar
      v-model="snackbarDeckIsNowFollowed"
      timeout=3000
      color="green"
      top
    >
      You are now following this deck and is accessible in 'My Decks'

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-sheet>

</template>

<script>
import service from "@/service";
import {mapActions, mapGetters} from "vuex";
import MiniCard from "@/components/MiniCard";
import debounce from "debounce";
import {DeckBO} from "@/Deck";
import CardEditorWithTabs from './CardEditorWithTabs.vue';
import authService from "@/authService";

let deckBO = null;

export default {
  name: 'DeckEditor',

  components: { CardEditorWithTabs, MiniCard },

  props: ["write"],

  data: () => ({
    lastElementClickedIsAMinicard: false,
    windowHeight: window.innerHeight,
    editTitle: false,
    selectedCard: 0,
    frontSideSelected: true,
    currentCard: {
      id: "",
      userId: "",
      deckId: "",
      front: "",
      back: ""
    },
    deck: {
      id: "",
      name: "",
      userId: "",
      type: "CLASSIC"
    },
    loadedCards: [],

    snackbarDeckIsNowFollowed: false,
    showFollowThisDeckButton: true,
  }),

  created() {
    this.updateDeckName = debounce(this.updateDeckName, 300)
  },
  
  beforeDestroy() {
    // this is to avoid to have more than one time the function handleClick registered
    // (eeach time we enter in the mounted function)
    window.removeEventListener("resize", this.handleClick)
    window.removeEventListener("click", this.handleClick)
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  mounted() {
    // utilisé pour recalculer le virtual-scroll des mini cards
    window.addEventListener('resize', this.onResize)
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('click', this.handleClick)

    console.log("write: " + this.write)
    this.deck.id = this.$route.params.deckId
    deckBO = new DeckBO()
    this.loadDeck(this.deck.id)
  },

  computed: {
    user: {
      get() {
        return this.getUser()
      },
    },
    editable: {
      get() {
        return this.deck.type === "CLASSIC" && this.write
      }
    },
    isOwner: {
      get() {
        console.log("isOwner:" +  this.getUser().id)
        return this.getUser().id === this.deck.userId
      }
    },
  },

  methods: {
    ...mapActions([
      'loadUser'
    ]),
    ...mapGetters([
      'getUser'
    ]),

    setFrontSideSelected(selected) {
      this.frontSideSelected = selected
      console.log("setting FrontSideSelected to " + selected)
    },

    onResize() {
      this.windowHeight = window.innerHeight
    },

    handleClick: function() {
      // this function is registered on all windows click
      // it is used to remove the flag so that we know that the last element clicked
      // is not a minicard.
      // on Minicard component event @click.stop set the flag to true and so sto propagation
      // so that flag is not set to false in this specific case
      this.lastElementClickedIsAMinicard = false
    },

    handleKeyDown: function(event) {
      if (event.key == "Backspace") {
        if (this.lastElementClickedIsAMinicard) {
          this.deleteCard()
        }
      }
    },

    deleteCard: async function() {
      try {
        await service.deleteCard(this.currentCard.userId, this.currentCard.deckId,
            this.currentCard.id)
      } catch (e) {
        alert(e)
        return
      }
      let selectedCardIndexBackUp = this.selectedCard
      this.loadedCards.splice(selectedCardIndexBackUp, 1)
      if (this.loadedCards.length > 0) {
        if (selectedCardIndexBackUp == this.loadedCards.length) {
          selectedCardIndexBackUp = selectedCardIndexBackUp - 1
        }
        const previousCard = this.loadedCards[selectedCardIndexBackUp]
        this.setCurrentCard(previousCard)
      } else {
        // there should be at least one card in a deck
        this.addCard()
      }
    },

    async followDeck() {
      if (!authService.isUserConnected()) {
        authService.signIn()
        return
      }
      this.showFollowThisDeckButton = false
      console.log("follow deck" + this.deck.id);
      await service.followDeck(this.deck.id)
      this.snackbarDeckIsNowFollowed = true
    },

    loadDeck: async function(deckId) {
      console.log("loading deck");
      // this.deck = await service.loadDeck(deckId)
      // console.log(this.deck)
      // let loadedCards = []
      // for (let i=0; i<this.deck.cards.length; i++) {
      //   const card = await service.loadCard(this.deck.id, this.deck.cards[i])
      //   loadedCards.push(card)
      // }
      await deckBO.loadDeck(deckId)
      this.deck = deckBO.getLoadedDeck()
      let loadedCards = deckBO.getLoadedCards()
      // the next line of is now useless because we load cards asynchronously
      // loadedCards.sort((a, b) => (a.creationEpochTime < b.creationEpochTime) ? 1 : -1)
      this.loadedCards = loadedCards
      this.setCurrentCard(this.loadedCards[0])
    },
    addCard: async function() {
      console.log("add card");
      const card = await service.addCard(this.deck.id)
      this.loadedCards.unshift(card)
      // this.selectedCard = 0
      this.setCurrentCard(card)
    },
    setCurrentCard: function(card) {
      this.currentCard = card
      // more info in handleClick function about lastElementClickedIsAMinicard
      this.lastElementClickedIsAMinicard = true
      this.selectedCard = this.getCardIndex(card.id)
      console.log("setCurrentCard this.selectedCard=" + this.selectedCard)
      this.setFrontSideSelected(true)
    },
    getCardIndex(cardId) {
      for (let i = 0; i < this.loadedCards.length; i++) {
        const c = this.loadedCards[i]
        if (c.id == cardId) {
          return i
        }
      }
    },
    onCardFrontSideChange: function({cardId, data}) {
      console.log("onCardFrontSideChange cardId=" + cardId)
      const card = this.loadedCards[this.getCardIndex(cardId)]
      card.front = data
      service.saveCard(this.currentCard)
      this.frontSideSelected = true
    },
    onCardBackSideChange: function({cardId, data}) {
      console.log("onCardBackSideChange this.cardId=" + cardId)
      const card = this.loadedCards[this.getCardIndex(cardId)]
      card.back = data
      service.saveCard(this.currentCard)
      this.frontSideSelected = false
    },

    // saveCard: function() {
    //   service.saveCard(this.currentCard)
    // },

    updateDeckName: function() {
      service.updateDeckName(this.deck.id, this.deck.name)
    }

    // loadCard: async function() {
    //   console.log("loadCard " + this.cardId);
    //
    //   const card = await service.loadCard(this.cardId);
    //   this.cardFrontSide = card.front
    //   this.cardBackSide = card.back
    // },

  }
}
</script>
