import service from "@/service";

export class DeckBO {

  constructor() {
    this.loadedDeck = {
      id: "",
      userId: "",
      name: "",
      cards: []
    },
    this.loadedCards = []
    // this.followedLoadedDeck = {
    //   id: "",
    //   userId: "",
    //   name: "",
    //   cards: []
    // },
    // this.followedLoadedCards = []
  }

  async loadDeck(deckId, withAllCards=true) {
    console.log("class loadDeck")
    this.loadedDeck = await service.loadDeck(deckId)

    if (withAllCards) {
      await this.loadCards(this.loadedDeck.cards)
    }

    // if (this.loadedDeck.type == "FOLLOW") {
    //   this.followedLoadedDeck = await service.loadDeck(this.loadedDeck.followedDeckId)
    // }
    // for (let i=0; i<this.followedLoadedDeck.cards.length; i++) {
    //   const card = await service.loadCard(this.followedLoadedDeck.id, this.followedLoadedDeck.cards[i])
    //   this.followedLoadedCards.push(card)
    // }
  }

  async loadCards(cardIds) {
    
    // load 10 first card synchronoulsy
    const nbCardsToLoadSynchronously = cardIds.length > 10 ? 10 : cardIds.length
    for (let i=0; i<nbCardsToLoadSynchronously; i++) {
      let card = await service.loadCard(this.loadedDeck.id, cardIds[cardIds.length-1-i])
      this.loadedCards.push(card)
    }

    // load all other cards asynchronously
    if (cardIds.length > 10) {
      const vm = this
      let mapCardIdToIndex = {}
      let newIndex = nbCardsToLoadSynchronously - 1
      for (let i = cardIds.length - 1 - nbCardsToLoadSynchronously; i >= 0; i--) {
        newIndex++
        mapCardIdToIndex[cardIds[i]] = newIndex
        // add a blank card to the end of the array
        vm.loadedCards.push(
            {
              "id": cardIds[i],
              "type": "CANVAS",
              "userId": this.loadedDeck.userId,
              "deckId": this.loadedDeck.id,
              "front": "{}",
              "back": "{}"
            }
        )
        service.loadCard(this.loadedDeck.id, cardIds[i]).then(
            (card) => {
              console.log("put card in index: " + mapCardIdToIndex[card.id])
              vm.loadedCards[mapCardIdToIndex[card.id]] = card
            }
        )
      }
    }
  }

  test() {

  }

  getLoadedDeck() {
    return this.loadedDeck
  }

  getLoadedCards() {
    // return [...this.loadedCards, ...this.followedLoadedCards]
    return this.loadedCards
  }

  getCardById(cardId) {
    // return  [...this.loadedCards, ...this.followedLoadedCards].find(card => card.id === cardId)
    return this.loadedCards.find(card => card.id === cardId)
  }

}