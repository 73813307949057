<template>
  <v-container fluid fill-height class="pa-0">

        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tab @click="onCardFrontSideSelected">Front</v-tab>
          <v-tab @click="onCardBackSideSelected">Back</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container fill-height class="pa-0">
                      <side-editor title="Front" :write="write" ref="front" :owner-id="ownerId" :deck-id="deckId" :card-id="cardId" :canvas-json="front"
                                  @cardSideChange="onCardFrontSideChange"
                                  :selected="frontSideSelected" />

            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fill-height class="pa-0">
              <side-editor title="Back" :write="write" ref="back" :owner-id="ownerId" :deck-id="deckId" :card-id="cardId" :canvas-json="back"
                          @cardSideChange="onCardBackSideChange"
                          :selected="!frontSideSelected" />
            </v-container>
          </v-tab-item>
        </v-tabs-items>

  </v-container>
</template>

<script>

import SideEditor from './SideEditor.vue';
  export default {
    name: 'CardEditor',
    props: {
      write: Boolean,
      ownerId: String,
      deckId: String,
      cardId: String,
      front: String,
      back: String,
      frontSideSelected: {
        type: Boolean,
        default: true
      },
    },
    components: {SideEditor},

    data: () => ({
      tab: null,
      updateCardFrontSide: "",
      updateCardBackSide: "",
    }),

    watch: {
      frontSideSelected: function(frontSideSelectedNewVal) {
        this.tab = frontSideSelectedNewVal ? 0 : 1;
      },

    },

    computed: {

    },

    mounted() {
      this.tab = this.frontSideSelected ? 0 : 1;
    },

    methods: {

      onCardFrontSideSelected: function() {
        this.tab=0
        this.$emit("cardFrontSideSelected")
      },

      onCardBackSideSelected: function() {
        this.tab=1
        this.$emit("cardBackSideSelected")
      },

      onCardFrontSideChange: function(data) {
        this.updateCardFrontSide = data
        this.$emit("cardFrontSideChange", { cardId: this.cardId, data })
      },

      onCardBackSideChange: function(data) {
        this.updateCardBackSide = data
        this.$emit("cardBackSideChange", { cardId: this.cardId, data })
      },



    },
  }
</script>
