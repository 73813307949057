<template>
  <v-row flat>
    
    <v-col>
    
      <v-card>
        <v-card-title class="text-h5">
          Well done ! No More card to learn for this session
        </v-card-title>
          <div>
            <Chart :values="boxesSummary"></Chart>
          </div>
      </v-card>

    </v-col>
        
  </v-row >
</template>

<script>
import Chart from './Chart.vue';  

export default {
  name: "LearningWelcomePage",

  components: {Chart},

  props: ["cardsToLearnCount", "boxesSummary"], 
  data: () => ({

  }),

  mounted() {

  },

  methods: {

  }

}
</script>
