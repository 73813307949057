import {configEndpoint} from "@/config";
import {getAuth} from "firebase/auth";

export let idToken = "undefined"

function saveIdToken(pIdToken) {
  idToken = pIdToken
  console.log("saved idToken: " + JSON.stringify(idToken))
}

async function fetchWithIdToken(path, request) {
  request.headers = new Headers({
    "Authorization": "Bearer " + idToken.token,
    "expirationtime": "" + idToken.expirationTime,
  })
  console.log("fetchWithIdToken: " + configEndpoint + path)
  const response = await fetch(configEndpoint + path, request)
  if (response.status === 401) {
    const newIdToken = await getAuth().currentUser.getIdTokenResult(true)
    saveIdToken(newIdToken)
    return await fetchWithIdToken(path, request)
  }
  return response
}

async function fetchBasic(path, request) {
  console.log("fetch: " + configEndpoint + path)
  const response = await fetch(configEndpoint + path, request)
  return response
}

export {
  saveIdToken,
  fetchWithIdToken,
  fetchBasic,
}