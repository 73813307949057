<template>
  <div>
    <v-app v-if="screen <= 400">
    
      <v-app-bar app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>
            <div>EasyFlashcard {{appEnv}}</div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn v-if="!isUserConnected()" @click="signIn" elevation="13" color="success">
          Login
        </v-btn>
        <v-btn v-if="isUserConnected()" @click="signOut" depressed>
          <v-icon>mdi-logout</v-icon>
        </v-btn>

      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
      >
        <v-list
          nav
        >
 
          <v-list-item link @click="drawer=false; $router.push('/mydecks')">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Decks</v-list-item-title>
          </v-list-item>
          
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid class="pa-0">
          <router-view></router-view>
        </v-container>
      </v-main>

    </v-app>

    <v-app v-if="screen > 400">
      <v-app-bar app clipped-left >

        <v-toolbar-title><div>EasyFlashcard {{appEnv}}</div></v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn v-if="!isUserConnected()" @click="signIn" elevation="13" color="success">
          Login
        </v-btn>
        <v-btn v-if="isUserConnected()" @click="signOut" icon>
          <v-icon alt="Sign out">mdi-logout</v-icon>
        </v-btn>
      </v-app-bar>
      

      <v-navigation-drawer v-if="isUserConnected()"
          permanent
          clipped
          app
      > <!-- expand-on-hover -->
        <v-list>
          <v-list-item  v-if="isUserConnected()" class="px-2">
            <v-list-item-avatar>
              <img :src="currentUser.photoURL" v-if="isUserConnected()">
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link v-if="isUserConnected()">
            <v-list-item-content>
              <v-list-item-title class="title" >
                {{currentUser.displayName}}
              </v-list-item-title>
              <v-list-item-subtitle >{{currentUser.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
            nav
            dense
        >
          <v-list-item link @click="$router.push('/search')">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Search decks</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push('/mydecks')">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>
            <v-list-item-title>My Decks</v-list-item-title>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <v-list
              nav
              dense
          >
<!--            <v-list-item v-if="isUserConnected()" link @click="signOut">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon>mdi-logout</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-title>Sign out</v-list-item-title>-->
<!--            </v-list-item>-->
            <v-list-item link @click="$router.push('/termsofuse')">
              <v-list-item-title>Terms of use</v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      

      <v-main>
        <v-container fluid class="pa-0">
          <router-view></router-view>
        </v-container>
      </v-main>

<!--      <v-footer padless>-->
<!--        <v-card-->
<!--            flat-->
<!--            tile-->
<!--            width="100%"-->
<!--            class="white lighten-5 text-center"-->
<!--        >-->

<!--          <v-divider></v-divider>-->

<!--          <v-card-text >-->
<!--            <a href="/#/termsofuse">Terms of use</a>-->
<!--            {{ new Date().getFullYear() }} — <strong>Easyflashcard</strong>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-footer>-->

    </v-app>

    <v-dialog
        v-model="showTermsOfUseDialog"
        persistent
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Website Terms of Use
        </v-card-title>

        <terms-of-use/>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="validateTermsOfUse()"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  
</template>

<script>
import { initializeApp } from 'firebase/app';
import {
  getAuth,
} from "firebase/auth";
import authService from '@/authService'
import {saveIdToken} from '@/ApiHelper'
import service from "@/service";
import {mapActions, mapGetters} from "vuex";
import {loadedTo} from '@/main'
import * as config from '@/config'
import termsOfUse from "@/components/TermsOfUse";

console.log(`---------- ${config.appEnv}`)

initializeApp({
  apiKey: config.firebaseWebApi,
  authDomain: config.authDomain,
});

export default {
  name: 'App',

  components: {
    termsOfUse
  },

  computed: {
    screen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
        default: return 400
      }
    },



  },

  async mounted() {
    const vm = this
    getAuth().onAuthStateChanged(async function(user) {
      console.log("onAuthStateChanged:")
      if (user) {
        console.log("user:" + JSON.stringify(user))
        vm.currentUser = user
        const idToken = await getAuth().currentUser.getIdTokenResult(true)
        console.log("idToken=" + idToken)
        saveIdToken(idToken)
        await service.userSignedIn(idToken.token)
        await vm.loadUser()
        vm.showTermsOfUseDialog = !vm.getUser().termsOfUseValidated

        if (loadedTo != undefined) {
          console.log("loadedTo is setted (meaning that was the page initialy loaded" + loadedTo)
          loadedTo()
        }
      }
    });
  },

  data: () => ({
    showTermsOfUseDialog: false,
    drawer: false,
    currentUser: undefined,
    signingIn: false,
    appEnv: config.appEnv,
  }),

  methods: {
    ...mapActions([
        'loadUser'
    ]),
    ...mapGetters([
      'getUser'
    ]),
    signIn() {
      authService.signIn()
    },
    signOut() {
      console.log("Signing Out")
      this.forceSignOut = true
      authService.signOut()
      document.location.reload();
    },
    isUserConnected() {
      return this.currentUser !== undefined
    },
    isTermsOfUseSigned() {
      return this.isUserConnected() && this.getUser() && this.getUser().termsOfUseValidated
    },
    validateTermsOfUse() {
      service.validateTermsOfUse()
      this.showTermsOfUseDialog = false
    }
  },

};
</script>
